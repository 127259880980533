@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: #ffffff;
  --foreground: #171717;
  scroll-behavior: smooth;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: #ffffff;
    --foreground: #171717;
  }
}

body {
  color: var(--foreground);
  background: var(--background);
  font-family: Arial, Helvetica, sans-serif;
}

.embla {
  position: relative;
  overflow: hidden;
  width: 100%; /* Full width */
  height: 100%; /* Set height as needed */
}

.embla__container {
  display: flex;
  height: 100%;
  transition: opacity 0.5s ease-in-out; /* Smooth fade */
}

.embla__slide {
  position: relative;
  min-width: 100%; /* Full width for each slide */
  height: 100%;
}

/* globals.css */
@font-face {
  font-family: "Massilia";
  src: url("/fonts/Massilia-Medium.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap; /* Ensures text is visible while the font loads */
}

/* Apply the font globally */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Massilia", sans-serif;
}
